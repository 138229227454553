

import React, { useState } from 'react';
import styles from './Header.module.css';
import { Link, useNavigate } from 'react-router-dom';
import Searchbar from '../Searchbar/Searchbar';
import { Icon } from '@iconify/react';
import { useSelector, useDispatch } from 'react-redux';
import { clearBasket, countBasketItems } from '../../features/basket/basketSlice';
import { showLoading, hideLoading } from '../../features/ui/uiSlice';
import { sendData } from '../../utils/fetchData';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EffectFade } from 'swiper/modules';
import 'swiper/css/effect-fade';

// import required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { setUserStatus, deleteUserDetails } from '../../features/user/userSlice';


const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const totalItems = useSelector(countBasketItems);
    const dispatch = useDispatch();

    const logOutUser = async () => {
        dispatch(showLoading());
        try {
            await sendData('/clienti/logout').then(() => { dispatch(clearBasket()) });
        } catch (err) {
            console.log('Eroare la logout: ', err)
        } finally {
            dispatch(setUserStatus(false));
            dispatch(deleteUserDetails());
            dispatch(hideLoading());
        }
    }

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    }

    return (
        <header className={styles.container}>
            <div className={styles.secondContainer}>
                <div className={styles.band}>
                    <Link to="/">
                        <button className={styles.logoButton}>
                            <img src="/logo.png" alt="Logo" className={styles.headerImage} />
                        </button>
                    </Link>
                </div>
                <div className={styles.band}>
                    <Searchbar />
                </div>
                <div className={styles.band}>
                    <div className={styles.bandIcons}>
                        <Link to="/cont">
                            <Icon icon="mdi:account-outline" color='black' inline='true' height='25px' />
                        </Link>
                    </div>

                    <div className={styles.bandIcons}>
                        <Link to="/cos">
                            <Icon icon="simple-line-icons:basket" color='black' inline='true' height='20px' />
                            {totalItems > 0 && (
                                <div className={styles.basketHeaderCount}>{totalItems}</div>
                            )}
                        </Link>
                    </div>
                    <div className={styles.logoutCountainer} onClick={logOutUser}>
                        <div className={styles.logoutButton}>Log Out</div>
                    </div>
                    <div className={styles.mobileMenuToggle} onClick={toggleMobileMenu}>
                        <Icon icon={mobileMenuOpen ? "mdi:close" : "mdi:menu"} color='black' inline='true' height='25px' />
                    </div>
                </div>
            </div>

            <div className={`${styles.mobileMenu} ${mobileMenuOpen ? styles.open : ''}`}>
                <Link to="/" onClick={toggleMobileMenu}>Acasa</Link>
                <Link to="/produse/gepeto" onClick={toggleMobileMenu}>Produse Gepeto</Link>
                <Link to="/produse/bacanie?page=1&categorie=8" onClick={toggleMobileMenu}>Lactate Miorita</Link>
                <Link to="/promotii" onClick={toggleMobileMenu}>Promotii</Link>
                <Link to="/cont" onClick={toggleMobileMenu}>Contul meu</Link>
                <div className={styles.logoutButton} onClick={() => { logOutUser(); toggleMobileMenu(); }}>Log Out</div>
            </div>

            <div className={styles.menuBand}>
                <div className={styles.menuBandContent}>
                    <Link style={{ margin: '0px 10px' }} to="/">
                        <button className={styles.menuButton}>
                            Acasa
                        </button>
                    </Link>
                    <Link style={{ margin: '0px 10px' }} to="/produse/gepeto">
                        <button className={styles.menuButton}>
                            Produse Gepeto
                        </button>
                    </Link>
                    <Link style={{ margin: '0px 10px' }} to="/produse/bacanie?page=1&categorie=8">
                        <button className={styles.menuButton}>
                            Lactate Miorita
                        </button>
                    </Link>
                    <Link style={{ margin: '0px 10px' }} to="/promotii">
                        <button className={styles.menuButton}>
                            Promotii
                        </button>
                    </Link>
                    <Link style={{ margin: '0px 10px' }} to="/cont">
                        <button className={styles.menuButton}>
                            Contul meu
                        </button>
                    </Link>
                </div>
            </div>
            <div style={{ padding: '0px !important' }} className={`${styles.menuBand} ${styles.promotionBand}`}>
                <OfferSwiper />
            </div>

        </header>
    );
}

export default Header;



export const OfferSwiper = () => {
    const navigate = useNavigate();

    const handleRedirect = (url) => {
        navigate(url);
    };

    const params = {
        spaceBetween: 0, /* Remove space between slides */
        loop: true,
        navigation: false,
        speed: 600,
        slidesPerView: 1, /* Ensure single slide view */
        centeredSlides: true, /* Center slides if needed */
    };

    const swiperContent = [
        { id: 2, nume: "Noi Produse Gepeto - Descopera Acum!", text: "/produse/gepeto" },
        { id: 3, nume: "Verifica Ofertele noastre!", text: "/produse/gepeto?sort=desc&oferta=true" },
    ];

    return (
        <Swiper
            effect="fade"
            {...params}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}

            modules={[Navigation, Pagination, Autoplay, EffectFade]}
            className={`${styles.someSwiper} hide-buttons`} // Combine CSS module with a regular class
        >
            {swiperContent.map(item => (
                <SwiperSlide key={item.id}>
                    <div
                        style={{
                            cursor: 'pointer',
                            padding: '10px',
                            textAlign: 'center',
                            fontSize: '16px',
                            backgroundColor: '#f0f0f0',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#333',
                            fontWeight: 'bold',
                        }}
                        onClick={() => handleRedirect('/promotii')}
                    >
                        {item.nume}
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
